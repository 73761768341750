import TaskFooter from '../Components/TaskFooter'
import TaskInfo from '../Components/TaskInfo'
import DescriptionTask from '../Elements/DescriptionTask'
import DeadlineCard from '../Elements/DeadlineCard'
import PriorityCard from '../Elements/PriorityCard'
import SubTaskProperties from '../Elements/SubTaskProperties'
import TagsCard from '../Elements/TagsCard'
import CommentsTask from '../Components/CommetsTask'
import NewComment from '../Elements/NewComment'
import UploadFileTask from '../Elements/UploadFileTask'
import FileTask from '../Elements/FileTask'

import { ReactComponent as IconPlus } from '../../assets/kanban/plus.svg'

import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { requests } from '../../requests'
import { updateCard } from '../../pipeline/BasicPipelines'
import { uploadFileTask } from '../../pipeline/FilePipeLines'
import { useEffect, useState } from 'react'
import { readUploadFile } from '../../pipeline/helpers'
import { deleteTaskPipeline } from '../../pipeline/BasicPipelines'

function TaskProperties() {
    const { taskId, projectId } = useParams()
    const task = useSelector(state => state.tasks.tasks.find(task => task.id === Number(taskId)))
    const project = useSelector(state => state.teams.projects.find(proj => proj.id === Number(projectId)))
    const tags = useSelector(state => state.tasks.tags)
    const [comments, setComments] = useState([])

    console.log(task, 'task')
    const navigate = useNavigate()

    const update = (taskNewInfo, settings) =>
        updateCard(
            {
                projectId,
                taskData: {
                    id: Number(taskId),
                    ...taskNewInfo,
                },
            },
            settings,
            () => requests.getTaskComments(taskId, res => setComments(res.comments))
        )

    const createSubTask = () => {
        requests.createSubtask(projectId, taskId, { title: '' }, res => {
            const newTask = {
                ...task,
                subAssignmentsResponse: [...task.subAssignmentsResponse, res],
            }

            update(newTask, { updateBackend: false })
        })
    }

    useEffect(() => {
        requests.getTaskComments(taskId, res => {
            setComments(res.comments)
        })

        return () => {}
    }, [taskId])

    const addComment = text => {
        requests.addComment(taskId, text, res => {
            const newComments = [...comments, res]

            setComments(newComments)
        })
    }

    const changeColumn = ({ statusId }) => {
        requests.moveToStatus(taskId, statusId, 0, () => {
            update({ statusId })
        })
    }

    const dragStartHandler = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    const dragLeaveHandler = e => {
        e.preventDefault()
        e.stopPropagation()
        if (e.currentTarget.contains(e.relatedTarget)) return
    }

    const dragDropHandler = e => {
        e.preventDefault()
        e.stopPropagation()

        let files = [...e.dataTransfer.files]
        readUploadFile(Number(taskId), files, uploadFileTask)
    }

    if (!task || !project) {
        return <></>
    }

    return (
        <div className="overlay overlay-task d-flex align-items-center justify-content-center w-100 h-100 overlay-show">
            <div
                className="overlay-wrap position-relative p-0 overlay-task-view"
                onDragStart={dragStartHandler}
                onDragLeave={dragLeaveHandler}
                onDragOver={dragStartHandler}
                onDrop={dragDropHandler}
            >
                <TaskFooter
                    onClose={() => navigate(`/project/${project?.id}`)}
                    taskId={taskId}
                />
                <div className="overlay-wrap__content w-100">
                    <TaskInfo
                        createTime={task.taskCreationTime}
                        statusInfo={project.statusesResponse.statuses}
                        status={task.statusId}
                        update={update}
                        executors={task.executors}
                        projectUsers={project.userInfos}
                        changeColumn={changeColumn}
                        onDelete={() => {
                            deleteTaskPipeline(Number(taskId))
                            navigate(-1)
                        }}
                    />
                    <div className="d-flex align-items-top w-100">
                        <div className="w-50 pt-9 pb-9 ps-6 pe-6 overlay-scroll">
                            <DescriptionTask title={task.title} description={task.description} update={update} />
                            <div className="mt-6">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <DeadlineCard deadline={task.deadline} update={update} typeView={'icon'} />
                                        <p className="ms-4 color-grey5 font-medium">Сроки</p>
                                    </div>
                                    <div className="d-flex align-items-center">{task.deadline}</div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-5">
                                    <div className="d-flex align-items-center">
                                        <PriorityCard priority={task.importanceScale} update={update} />
                                        <p className="ms-4 color-grey5 font-medium">Приоритет</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <PriorityCard priority={task.importanceScale} isTitle={true} isChange={false} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-5 w-100">
                                    <div className="d-flex align-items-center w-50 flex-shrink">
                                        <TagsCard allTags={tags} update={update} />
                                        <p className="ms-4 color-grey5 font-medium">Метки</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end flex-wrap w-500">
                                        {task.tags.map(tag => (
                                            <TagsCard key={tag.id} tag={tag} allTags={tags} update={update} />
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="text-large">Подзадачи</p>
                                        <>
                                            <IconPlus onClick={() => createSubTask()} className="cursor-pointer" />
                                        </>
                                    </div>

                                    <div className="mt-4">
                                        {task?.subAssignmentsResponse?.map(subtask => (
                                            <SubTaskProperties
                                                key={subtask.id}
                                                title={subtask.title}
                                                isDone={subtask.checked}
                                                update={data => {
                                                    requests.changeSubtask(projectId, subtask.id, {
                                                        ...data,
                                                        id: subtask.id,
                                                    })

                                                    const newTask = {
                                                        ...task,
                                                        subAssignmentsResponse: task.subAssignmentsResponse.map(
                                                            item => {
                                                                if (item.id === subtask.id) {
                                                                    return {
                                                                        ...item,
                                                                        ...data,
                                                                    }
                                                                }

                                                                return item
                                                            }
                                                        ),
                                                    }

                                                    update(newTask, { updateBackend: false })
                                                }}
                                            />
                                        ))}

                                    </div>
                                    {/* <div className="mt-4 d-flex align-items-center p-2 cursor-pointer">
                                        {{> app-subtasks/subtasks-add }}
                                    </div> */}
                                </div>
                                <div className="mt-6 w-100">
                                    <p className="text-large">Вложения</p>
                                    <div className="mt-4 d-flex flex-wrap w-100 gap-24">
                                        {task.filesData.map(({ id, fileName }) => (
                                            <FileTask key={id} fileId={id} name={fileName} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CommentsTask comments={comments} />
                    </div>
                    <div className="overlay-wrap__content-footer d-flex align-items-center w-100 bg-light3">
                        <UploadFileTask taskId={Number(taskId)} onUploadFile={uploadFileTask} />
                        <NewComment create={addComment} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TaskProperties
