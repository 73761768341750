import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
//import { requests } from '../../requests'
//import { setTasks } from '../../store/TaskProperties'
import { updateCard } from '../../pipeline/BasicPipelines'

import ListHeader from '../Elements/ListHeader'
import ListTask from '../Components/ListTask'

function ListBoard() {
    const { projectId } = useParams()
    //const dispatch = useDispatch()

    //const setTasksBoard = newTasks => dispatch(setTasks(newTasks))

    const tasks = useSelector(state => state.tasks.tasks)
    const tags = useSelector(state => state.tasks.tags)
    const project = useSelector(state => state.teams.projects.find(item => item.id === Number(projectId)))

    const statuses = project?.statusesResponse?.statuses || []

    // const createNewTask = (column, title) => {
    //     requests.createTask(projectId, title, column, data => {
    //         const newTasks = [data, ...tasks]
    //         setTasksBoard(newTasks)
    //     })
    // }

    console.log(statuses, 'statuses')
    const update = (taskData, settings) => updateCard({ projectId, taskData }, settings)

    return (
        <>
            {/* {{> app-table/table-header }} */}
            <ListHeader />
            <div className="app-table__content mt-9">
                {tasks.map(task => (
                    <ListTask
                        key={task.id}
                        task={task}
                        allTags={tags}
                        projectUsers={project.userInfos}
                        updateCard={update}
                        statusInfo={statuses.find(status => status.statusId === task.statusId)}
                    />
                ))}
                {/* <div className="js--subtable">
                    {{> app-table/row-active }}
                    <div className="app-table__subtitle js--subtable-content">
                        {{> app-table/row }}
                        {{> app-table/row2 }}
                        {{> app-table/row3 }}
                    </div>
                </div>
                {{> app-table/row4 }}
                {{> app-table/row5 }}
                {{> app-table/row6 }}
            </div>
            <div className="app-table__new mt-9 js--table-add">
                <div className="cursor-pointer d-flex align-items-center js--table-add--btn">
                    {{> app-subtasks/subtasks-add }}
                </div>
                <div className="app-table__new-row js--table-add--content">
                    {{> app-table/row-add }}
                </div> */}
            </div>
        </>
    )
}

export default ListBoard
