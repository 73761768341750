import { ReactComponent as AvatarIcon } from '../../assets/kanban/avatar.svg'

import { useRef } from 'react'
import { useStateVisibleComponent } from '../CustomHooks/ClickOutside'

import { uploadExecutorTask } from '../../pipeline/BasicPipelines'
import IconAvatar from '../Elements/IconAvatar'
import SelectUserTask from '../Elements/SelectUserTask'

function ExecutorTask({ users, selectUsers, taskId }) {
    const ref = useRef(null)
    const [isOpenOverlayAvatar, setIsOpenOverlayAvatar] = useStateVisibleComponent(ref)

    return (
        <div
            className="app-dropdown app-dropdown__avatar cursor-pointer position-relative svg-block js--dropdown"
            ref={ref}
        >
            <div
                className="app-card__avatar flex-center font-medium text-medium m-1 flex-shrink js--dropdown-open"
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsOpenOverlayAvatar(!isOpenOverlayAvatar)
                }}
            >
                {selectUsers?.[0] &&
                    selectUsers.map(user => (
                        <div className='d-flex align-items-center' style={{ marginLeft: '-12px' }}>
                            <IconAvatar
                                userId={user.userId}
                                addClass="circle-24 bg-violet color-white text flex-center me-2"
                            />
                        </div>
                    ))}

                {!selectUsers?.[0] && <AvatarIcon />}
            </div>

            {isOpenOverlayAvatar && (
                <SelectUserTask includesUsers={selectUsers} addUsers={users} update={uploadExecutorTask} taskId={taskId}/>
            )}
        </div>
    )
}

export default ExecutorTask
