import { useDispatch } from 'react-redux'
import { WorkSpace } from './WorkSpace'
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import LogIn from './view/Components/LogIn'
import Registration from './view/Components/Registration'
import Notification from './view/Components/Notification'
import ProxyUrlAddUserToTeam from './view/Components/ProxyUrlAddUserToTeam'
import TeamView from './view/Containers/TeamView'

import { initWorkArea, logInPipeline, registrationPipeline, navigateTofirstTeam } from './pipeline/InitPipelines'
import { resetPassword } from './pipeline/BasicPipelines'

function App() {
    let navigate = useNavigate()
    let location = useLocation()

    let from = location.state?.from?.pathname || '/'
    const to = () => navigate(from, { replace: true })

    const dispatch = useDispatch()
    let auth = useAuth()

    useEffect(() => {
        if (auth) {
            initWorkArea(dispatch, ({ teams }) => {

                if (location.pathname === "/") {
                    const teamId = teams[0].id
                    navigate(`/team/${teamId}`, { replace: true })
                }
            })
        }

        return () => {}
        // eslint-disable-next-line
    }, [ auth ])

    useEffect(() => {
        if (location.pathname === "/") {
            navigateTofirstTeam(navigate)
        }

        return () => {}
    }, [ location, navigate ])

    return (
        <div className="App">
            <Routes>
                <Route
                    path="/login"
                    element={
                        <LogIn
                            switcToRegistrate={() => navigate(`/registration`)}
                            on={(email, password) => logInPipeline(email, password, to)}
                            onReset={email => resetPassword(email)}
                        />
                    }
                />
                <Route
                    path="/registration"
                    element={<Registration switcToLogIn={() => navigate(`/login`)} on={registrationPipeline} />}
                />
                <Route
                    path="/team/:teamId/*"
                    element={
                        <RequireAuth>
                            <TeamView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/project/:projectId/*"
                    element={
                        <RequireAuth>
                            <WorkSpace />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/inviteProxy"
                    element={
                        <RequireAuth>
                            <ProxyUrlAddUserToTeam />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/*"
                    element={
                        <RequireAuth>
                            <Default />
                        </RequireAuth>
                    }
                />
            </Routes>
            <Notification />
        </div>
    )
}

function RequireAuth({ children }) {
    let auth = useAuth()
    let location = useLocation()

    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children
}

function Default() {
    // let navigate = useNavigate()
    // let auth = useAuth()

    //const dispatch = useDispatch()

    // useEffect(() => {
    //     if (auth) {
    //         initWorkArea(dispatch, id => {
    //             navigate(`/project/${id}`, { replace: true })
    //         })
    //     }

    //     return () => {}
    // }, [auth, navigate, dispatch])

    return <></>
}

function useAuth() {
    const time = window.localStorage.getItem('timeSaveToken')
    if (!time) return false

    const timeToken = 43000
    const liveToken = time && Math.round(Date.now() / 1000) - time < timeToken ? true : false

    return liveToken
}

export default App

