

function ListSubtask({ subtask }) {
    
    return (
        <div className="app-table__row d-flex align-items-center justify-content-between w-100">
            <div className="app-table__col w-100">
                <div className="app-table__content w-100 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="p-6"></div>
                        <div className="p-5"></div>
                        {/* {{> app-tooltip/tooltip-status-work }} */}
                        <p className="font-medium ms-4">{ subtask.title }</p>
                        {/* <div className="app-table__hidden ms-2">
                            {{> app-tooltip/tooltip-menu2 }}
                        </div> */}
                    </div>
                    {/* <div className="app-table__hidden">
                        {{> app-tooltip/tooltip-subtitle2 }}
                    </div> */}
                </div>

            </div>
            {/* <div className="app-table__col w-100 d-flex justify-content-center flex-wrap">
                {{> app-dropdown/dropdown-command }}
            </div>
            <div className="app-table__col w-100 d-flex justify-content-center">
                <p className="font-medium color-grey-table">Не выбрано</p>
            </div>
            <div className="app-table__col w-100 p-2 d-flex justify-content-center">
                {{> app-dropdown/dropdown-avatar2 }}
            </div>
            <div className="app-table__col w-100 d-flex justify-content-end pe-4">
                <div className="w-100 d-flex justify-content-center">
                    {{> app-dropdown/dropdown-priority3 }}
                </div>
                {{> app-dropdown/dropdown-subtask }}
            </div> */}
        </div>
    )
}

export default ListSubtask