import { ReactComponent as IconPlus } from '../../assets/kanban/plus2.svg'
// import { ReactComponent as IconArrowLeft } from '../../assets/kanban/arrow-left.svg'
import { TextAndInput } from '../Elements/MiniComponents'
import { requests } from '../../requests'
import { useParams } from 'react-router-dom'

function TitleColumnKanban({ setOpenAddTask, isOpenAddTask, title, color, id }) {
    const { projectId } = useParams()
    return (
        <div className="app-boards__top d-flex align-items-center justify-content-between mt-4 mb-4">
            <div className="d-flex align-items-center">
                <div className="app-boards__status me-1" style={{ background: color }}></div>
                {/* <p className="text-large js--board-hide"> {title} </p> */}
                <TextAndInput
                    text={title}
                    preholder={'Введите название колонки'}
                    addClass="text-large js--board-hide"
                    update={data => requests.changeColumnData(projectId, id, { title: data })}
                    isCheckEnter
                />
            </div>
            <div className="d-flex align-items-center svg-block">
                {/* <div className="me-1 cursor-pointer js--board-btn">
                    <IconArrowLeft />
                </div> */}
                <div
                    className="me-1 cursor-pointer js--board-hide js--board-search-btn"
                    onClick={() => setOpenAddTask(!isOpenAddTask)}
                >
                    <IconPlus />
                </div>
            </div>
        </div>
    )
}

export default TitleColumnKanban
