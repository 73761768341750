

function ListHeader() {
    return (
        <>
        <div className="app-table__header mt-9 d-flex align-items-center justify-content-between w-100">
            <div className="app-table__header-title">
                <p className="color-grey-title w-100 p-4"><span className="me-1">5</span>Задач</p>
            </div>
            <div className="app-table__header-title">
                <p className="color-grey-title text-center w-100 p-4">Метки</p>
            </div>
            <div className="app-table__header-title">
                <p className="color-grey-title text-center w-100 p-4">Сроки</p>
            </div>
            <div className="app-table__header-title">
                <p className="color-grey-title text-center w-100 p-4">Исполнитель</p>
            </div>
            <div className="app-table__header-title">
                <p className="color-grey-title text-center w-100 pt-4 pb-4 pe-6">Приоритет</p>
            </div>
        </div>
        </>
    )
}

export default ListHeader