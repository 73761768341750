import { useSelector, useDispatch } from 'react-redux'
import { changeClientInfo, changePassword } from '../../pipeline/BasicPipelines'
import { setClientInfo } from '../../store/Users'
import { useState, useRef } from 'react'
import { requests } from '../../requests'
import { readUploadFile } from '../../pipeline/helpers'
import { loadingUserAvatar } from '../../pipeline/InitPipelines'

import IconAvatar from '../Elements/IconAvatar'

import InputPassword from '../Elements/InputPassword'

function UserProfile() {
    const userInfo = useSelector(state => state.users.clientInfo)
    const dispatch = useDispatch()

    const inputFile = useRef()

    const [pass, setPass] = useState('')

    const choiseUploadFiles = () => {
        const input = inputFile.current
        const files = input.files

        readUploadFile(userInfo.userId, files, (id, fileData) => {
            requests.changeClientAvatar(fileData, () => loadingUserAvatar(userInfo.userId))
        })
    }

    return (
        <div className="page-cabinet__content js--cabinet-content active">
            <div className="p-9 ps-7 pe-7">
                <p className="text-big font-medium">Личная информация</p>
            </div>
            <div className="app-border10 w-100"></div>
            <div className="p-7 d-flex justify-content-between align-items-start">
                <div className="w-100 maxW-400">
                    <div className="">
                        <p className="font-medium mb-4">Имя</p>
                        <div className="input-basic">
                            <input
                                onBlur={() => {
                                    changeClientInfo({ newName: userInfo.name })
                                }}
                                value={userInfo.name}
                                onChange={e => {
                                    dispatch(setClientInfo({ name: e.target.value }))
                                }}
                                type="text"
                                placeholder="Иван Иванов"
                                className="ps-4 pe-4 w-100 h-100"
                            />
                        </div>
                    </div>
                    {/* <div className="mt-9">
                        <p className="font-medium mb-4">Роль</p>
                    </div> */}
                </div>
                <div className="ms-2 me-4 d-flex flex-column">
                    <p className="font-medium mb-4">Твой аватар</p>
                    {/* {{> app-upload/upload-avatar2 }} */}
                    <div className="app-form__upload form-upload d-flex align-items-center position-relative cursor-pointer">
                        {/* <fieldset>
                            <input type="hidden" id="MAX_FILE_SIZE" name="MAX_FILE_SIZE" value="300000" />
                        </fieldset> */}
                        <input
                            type="file"
                            id="fileselect"
                            ref={inputFile}
                            multiple={false}
                            onChange={choiseUploadFiles}
                            className="cursor-pointer w-100 h-100"
                        />
                        <div className="d-flex flex-column maxW-160" id="filedrag">
                            <IconAvatar
                                userId={userInfo.userId}
                                addClass="app-upload__avatar flex-center font-bold maxW-160"
                            />
                            <p className="btn btn-grey2 mt-4 p-10 ps-4 pe-4 h-max"> Загрузить фото</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="app-border10 w-100"></div>
            <div className="p-7 pe-0">
                <p className="font-medium mb-4">Язык</p>
                <div className="mt-4" style={{ maxWidth: '400px;' }}>
                </div>
            </div> */}
            <div className="p-7 pt-0 w-100">
                <div className="app-border10 w-100"></div>
                <div className="d-flex align-items-center justify-content-between w-100 mt-7">
                    <p className="font-medium">Email адрес</p>
                    {/* <p className="color-blue text-underline cursor-pointer">Изменить</p> */}
                </div>
                <p className="mt-5">
                    Ваш адрес почты &nbsp; {userInfo.email}
                    {/* <div className="font-bold2 text-underline-none">
                        {userInfo.email}
                    </div> */}
                </p>
                {/* <div className="app-border10 w-100 mt-7"></div> */}
                {/* <div className="d-flex align-items-center justify-content-between w-100 mt-7">
                    <p className="font-medium">Телефон</p>
                    <p className="color-blue text-underline cursor-pointer">Изменить</p>
                </div>
                <p className="mt-5">
                    Ваш номер телефона{' '}
                    <a href="" className="font-bold2 text-underline-none">
                        89312466491
                    </a>
                </p> */}
                <div className="app-border10 w-100 mt-7"></div>
                <div className="d-flex align-items-center justify-content-between w-100 mt-7">
                    <div className="w-100 maxW-400">
                        <p className="font-medium">Пароль</p>
                        <InputPassword password={pass} onChange={setPass} addClass="h-100 mt-9" />
                    </div>
                    <p className="color-blue text-underline cursor-pointer" onClick={() => changePassword(pass)}>
                        Изменить
                    </p>
                </div>
                {/* <div className="app-border10 w-100 mt-7"></div>
                <p className="color-blue text-underline cursor-pointer mt-7">Удалить мой профиль</p>
                <p className="mt-9 text-little">
                    Вы получите электронное письмо для подтверждения вашего решения. Это не приведет к прекращению вашей
                    подписки или платежей, и с вас будет по-прежнему взиматься плата. Вы можете отменить подписку или
                    изменить способ оплаты, чтобы команда оставалась активной.
                </p>
                <p className="text-little">
                    Вы получите электронное письмо для подтверждения вашего решения. Это не приведет к прекращению вашей
                    подписки или платежей, и с вас будет по-прежнему взиматься плата. Вы можете отменить подписку или
                    изменить способ оплаты, чтобы команда оставалась активной.
                </p> */}
            </div>
        </div>
    )
}

export default UserProfile
