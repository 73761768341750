import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { requests } from '../../requests'
import { addNewUserToTeamNotification } from '../../pipeline/notificationPipeline'
import { getParticipantsLabelRussian } from '../../pipeline/helpers'
import { deleteUserOnTeam } from '../../pipeline/BasicPipelines'
import TeamUserRow from './TeamUserRow'
import PopupCreateNewEntity from '../Elements/PopupCreateNewEntity'

function TeamUsers() {
    const { teamId } = useParams()
    const teamData = useSelector(state => state.teams.teams.find(item => item.id === Number(teamId)))

    const [isViewCreatePopup, setViewCreatePopup] = useState(false)

    return (
        <div className="page-cabinet__content js--cabinet-content active">
            <div className="p-9 ps-7 pe-7 d-flex align-items-center justify-content-between">
                <p className="text-big font-medium">Пользователи</p>
                <button className="btn btn-blue btn-border-none d-flex align-items-center svg-block p-0" onClick={() => setViewCreatePopup(!isViewCreatePopup)}>
                    <span className="me-2">{/* {{> _images/plus5 }} */}</span>
                    Пригласить нового пользователя
                </button>
            </div>
            <div className="app-border10 w-100"></div>
            <div className="ps-7 pe-7 w-100">
                <div className="d-flex align-items-center svg-block w-100 mt-9 mb-9">
                    <div className="cursor-pointer">{/* {{> _images/search }} */}</div>
                    <input type="text" placeholder="Поиск по имени или email " className="w-100 h-100 ms-2" />
                </div>

                <div className="app-border10 w-100"></div>
            </div>
            <div className="app-table__cabinet ps-7 pe-7 w-100">
                <div className="app-table__cabinet-row d-flex align-items-center justify-content-between pt-9 pb-9">
                    <div className="app-table__cabinet-col">
                        <p className="font-medium">Имя</p>
                    </div>
                    <div className="app-table__cabinet-col d-flex flex-shrink">
                        {/* <p className="font-medium">Роль</p> */}
                    </div>
                    <div className="app-table__cabinet-col d-flex flex-shrink">
                        <p className="font-medium">Права доступа</p>
                    </div>
                    <div className="app-table__cabinet-col d-flex flex-shrink">
                        <p className="font-medium">Последняя активность</p>
                    </div>
                    <div className="app-table__cabinet-col d-flex flex-shrink">
                        <div className="ps-9"></div>
                    </div>
                </div>
            </div>
            <div className="app-table__cabinet-blue pt-9 pb-9 ps-7 pe-7">
                <p className="color-white">
                    <span className="font-bold2">{teamData?.membersInfo.length} {getParticipantsLabelRussian(teamData?.membersInfo.length)}</span> ниже находятся в команде
                </p>
            </div>
            <div className="app-table__cabinet ps-7 pe-7 w-100">
                {teamData?.membersInfo.map(({ userId }) => (
                    <TeamUserRow
                        key={userId}
                        userId={userId}
                        accessRights={teamData.superuser.userId === userId ? 'Администратор' : 'Участник'}
                        onDeleteUser={teamData.superuser.userId === userId ? false : () => requests.deletUsersOnTeam(
                            teamId, userId, () => deleteUserOnTeam(Number(teamId), userId)
                        )}
                    />
                ))}
            </div>

            <PopupCreateNewEntity
                isView={isViewCreatePopup}
                title={`Пригласить в ${teamData.title}`}
                label='Email'
                placeholder='name@example.com'
                buttonTitle='Пригласить'
                onClose={() => setViewCreatePopup(false)}
                onCreate={text => {
                    setViewCreatePopup(false)
                    requests.addNewUserToTeam(teamId, text, addNewUserToTeamNotification)
                }}
            />
        </div>
    )
}

export default TeamUsers
