import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { requests } from '../../requests'
import { useDispatch } from 'react-redux'
import { changeViewNotification } from '../../store/Notification'

function ProxyUrlAddUserToTeam() {
    const [searchParams] = useSearchParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const teamId = searchParams.get('teamId')
        const invitationToken = searchParams.get('invitationToken')
        const projectId = searchParams.get('projectId')

        requests.acceptInvitation(
            invitationToken,
            teamId,
            projectId,
            () => {
                navigate('/team/' + teamId)
            },
            () => {
                const notificationError = {
                    isView: true,
                    type: 'error',
                    text: 'Извиняемся, что-то пошло не так. Попробуйте запросить приглашение еще раз',
                }

                dispatch(changeViewNotification(notificationError))
                navigate('/')
            }
        )
    })

    return <></>
}

export default ProxyUrlAddUserToTeam
