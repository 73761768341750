import { useState } from 'react'
import { searchInStrings } from '../../pipeline/helpers'
import { ReactComponent as Icon } from '../../assets/kanban/iconUser.svg'
import { ReactComponent as IconClose } from '../../assets/kanban/close.svg'
import { ReactComponent as IconPlus } from '../../assets/kanban/plus2.svg'

import IconAvatar from './IconAvatar'

function SelectUserTask({ includesUsers, addUsers, update, taskId }) {
    const [searchValue, setSearchValue] = useState('')

    return (
        <div className="app-dropdown__overlay app-dropdown__overlay-avatar js--dropdown-content p-10 open">
            <div className="input-grey2 d-flex align-items-center pt-10 pb-10 ps-1 pe-1 svg-block">
                <div className="d-flex flex-shrink svg-w--16">
                    <Icon />
                </div>
                <input
                    type="text"
                    placeholder="Начните вводить имя"
                    className="w-100 ms-2"
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                />
            </div>
            {includesUsers &&
                searchInStrings(searchValue, includesUsers, 'name').map(user => (
                    <div
                        key={user.userId}
                        className="app-dropdown__avatar-search mt-3 d-flex align-items-center justify-content-between p-1"
                        onClick={e => {
                            e.stopPropagation()
                            update(Number(taskId), user.userId)
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <IconAvatar
                                userId={user.userId}
                                addClass="app-card__avatar flex-center font-medium text-medium me-1 flex-shrink js--dropdown-open"
                            />
                            <p className="text-little p-0">{user.name}</p>
                        </div>
                        <div className="svg-block d-flex flex-shrink cursor-pointer svg-w--8">
                            <IconClose />
                        </div>
                    </div>
                ))}

            {addUsers &&
                searchInStrings(searchValue, addUsers, 'name').map(user => (
                    <div
                        key={user.userId}
                        className="mt-3 d-flex align-items-center justify-content-between p-1"
                        onClick={e => {
                            e.stopPropagation()
                            update(Number(taskId), user.userId)
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <IconAvatar
                                userId={user.userId}
                                addClass="app-card__avatar flex-center font-medium text-medium me-1 flex-shrink js--dropdown-open"
                            />
                            <p className="text-little p-0">{user.name}</p>
                        </div>
                        <div
                            className="svg-block d-flex flex-shrink cursor-pointer svg-w--16"
                            onClick={() => update(Number(taskId), user.userId)}
                        >
                            <IconPlus />
                        </div>
                    </div>
                ))}
        </div>
    )
}

export default SelectUserTask

