import LeftMenu from '../Components/LeftMenu'
import TeamInfo from '../Components/TeamInfo'
import Header from './Header'
import ProjectsMenuView from '../Components/ProjectsMenuView'
import TeamSetting from './TeamSetting'

import { useSelector } from 'react-redux'
import { useParams, Routes, Route } from 'react-router-dom'

import UserProfile from '../Components/UserProfile'
import TeamProfile from '../Components/TeamProfile'
import TeamUsers from '../Components/TeamUsers'
import TeamSettingTariff from '../Components/TeamSettingTariff'
import TeamSupport from '../Components/TeamSupport'

// import imgBack from '../../assets/menu/Remove-bg2.svg'
// import imgBack_2 from '../../assets/menu/Remove-bg1.svg'

import imgBack_3 from '../../assets/menu/Mask-group.svg'
import imgBack_4 from '../../assets/menu/Mask-group1.svg'

// import imgBack_5 from '../../assets/menu/Mask-group2.svg'
// import imgBack_6 from '../../assets/menu/Mask-group3.svg'

function TeamView() {
    const { teamId } = useParams()

    const team = useSelector(state => state.teams.teams.find(t => t.id === Number(teamId)))
    const projects = useSelector(state => state.teams.projects.filter(p => p.teamId === Number(teamId)))

    return (
        <>
            <Routes>
                {/* <Route path="/setting/*" element={<TeamSetting />} /> */}
                <Route
                    path="/userProfile"
                    element={
                        <TeamSetting>
                            <UserProfile />
                        </TeamSetting>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <TeamSetting>
                            <TeamProfile />
                        </TeamSetting>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <TeamSetting>
                            <TeamUsers />
                        </TeamSetting>
                    }
                />
                <Route
                    path="/tariff"
                    element={
                        <TeamSetting>
                            <TeamSettingTariff />
                        </TeamSetting>
                    }
                />
                <Route
                    path="/support"
                    element={
                        <TeamSetting>
                            <TeamSupport />
                        </TeamSetting>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <main className="d-flex h-100">
                            <LeftMenu type={'teams'} />
                            {team && <TeamInfo team={team} />}

                            {team && (
                                <div className="app-content p-6 pt-9 w-100">
                                    <div className="add-back-1">
                                        <img src={imgBack_3} alt="background" />
                                    </div>
                                    <Header />

                                    <ProjectsMenuView projects={projects} />
                                </div>
                            )}

                            <div className="add-back-2">
                                <img src={imgBack_4} alt="background" />
                            </div>
                        </main>
                    }
                />
            </Routes>
        </>
    )
}

export default TeamView
