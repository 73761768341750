import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as IconArrow } from '../../assets/task/arrow3.svg'
import TeamDropdownSelectItem from '../Elements/TeamDropdownSelectItem'

function TeamDropdownSelect() {
    const { teamId } = useParams()
    const navigate = useNavigate()
    const teams = useSelector(state => state.teams.teams)
    const team = teams.find(te => te.id === Number(teamId))

    const [isView, setView] = useState(false)

    return (
        <div className="app-dropdown app-dropdown__profile cursor-pointer position-relative svg-block js--dropdown" onClick={() => setView(!isView)}>
            <div className="app-dropdown__open cursor-pointer js--dropdown-open">
                <div className="d-flex justify-content-between w-100">
                    <TeamDropdownSelectItem id={team.id} name={team.title} redirect={() => {}} />
                    <IconArrow />
                </div>
            </div>
            {isView && <div className="app-dropdown__overlay js--dropdown-content overlay-team overflow-hidden-x scroll-y open">
                {teams.map(({ id, title }) => (
                    <TeamDropdownSelectItem key={id} id={id} name={title} redirect={() => navigate(`/team/${id}`)} addClassStyle="p-4" />
                ))}
            </div>}
        </div>
    )
}

export default TeamDropdownSelect
